<template>
  <div class="box">
    <div class="right" >
      <div class="righttop">
        <div class="righttopleft">{{ company_name }}({{  pageProps.total}}人)</div>
      </div>
      <div class="rightmiddle">
        <div class="xl" style="cursor: pointer;">
          <p @click="addUser">添加成员</p>
        </div>
      </div>

    <div>
      <el-table
          ref="table"
          v-loading="searchLoading"
          :data="pageProps.records"
          element-loading-text="正在查询"
          fit
          stripe
          highlight-current-row
        >
        
        <el-table-column prop="alias_name" label="姓名" align="center">
        </el-table-column>
      
        <el-table-column prop="ident" label="职位" align="center">
        </el-table-column>

        <el-table-column prop="mobile" label="手机号" align="center">
        </el-table-column>

        

        <el-table-column prop="audit_status" label="状态" align="center">
        </el-table-column>

        <el-table-column label="操作" fixed="right" align="center" class-name="small-padding "  width="250px">
            <template slot-scope="{row}">
            
              <el-button  type="primary" size="mini" @click="detail(row.user_app_id)">
                详情
              </el-button>

              <el-button  type="warning" size="mini" @click="del(row.user_app_id)">
                删除
              </el-button>

              <el-button  type="danger" size="mini" @click="disable(row.user_app_id)">
                禁用
              </el-button>
             </template> 
             
        </el-table-column>

       
      </el-table>
    </div>

        <div style="text-align:center;padding:20px">
          <!--分页-->
          <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageProps.total"
              :page-size="pageProps.pageSize"
              :page-sizes="[5, 10, 50, 100]"
              :current-page="pageProps.pageNum"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
        </div>  


    </div>
    <address-add-dialog ref="addressAddDialog2" @refresh="fetchData"></address-add-dialog>
    <address-edit-dialog ref="addressEditDialog2" @refresh="fetchData"></address-edit-dialog>
   
  </div>
</template>
<script>
  import addressAddDialog from './dialog/address-add-dialog.vue';
  import addressEditDialog from './dialog/address-edit-dialog.vue';
  import { companyBookList,companyBookDel,companyBookUpdate } from "@/api/address/index";

export default {
 
  components: { addressAddDialog,addressEditDialog},
  data() {
    return {
      company_name:localStorage.getItem("company_name"),
      searchLoading: false,
        pageProps: {
          records:[],
          total: 0,
          pageSize: 10,
          pageNum: 1
        },

        searchFormData:{
          company_user_type	:"3",
          page:0,
          limit:0
        },
    };
  },
  mounted(){
    this.fetchData();
  },
  methods: {
    fetchData(){
        var that = this;
        that.searchFormData.page=that.pageProps.pageNum;
        that.searchFormData.limit=that.pageProps.pageSize;

        companyBookList(that.searchFormData).then((response) => {
          console.log(response);
          if(response.code==200){
            that.pageProps.records = response.data.data;
            that.pageProps.total = response.data.total;
          }else{
            that.$message.error(response.msg?response.msg:"请求错误");
          }
         
        });
      },

       // 当前页变更
      handleCurrentChange(num) {
        this.pageProps.pageNum = num
        this.fetchData()
      },


      // 每页显示页面数变更
      handleSizeChange(size) {
        this.pageProps.pageSize = size
        this.fetchData()
      },

      addUser(){
        this.$refs.addressAddDialog2.init();
      },

      detail(user_app_id){
        this.$refs.addressEditDialog2.init(user_app_id);
      },

      del(user_app_id){
        var that=this;
        this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
            companyBookDel({user_app_id:user_app_id}).then((response) => {
              console.log(response);
             
              if(response.code==200){
                that.fetchData();
                that.$message.success(response.msg?response.msg:"操作成功");
              }else{
                that.$message.warning(response.msg?response.msg:"获取信息失败");
              }
            });
          
        }).catch(() => {
        
        });
    },

    disable(user_app_id){
        var that=this;
        this.$confirm('确定禁用?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          companyBookUpdate({user_app_id:user_app_id,status:'4'}).then((response) => {
              console.log(response);
              if(response.code==200){
                that.fetchData();
                that.$message.success(response.msg?response.msg:"操作成功");
              }else{
                that.$message.warning(response.msg?response.msg:"获取信息失败");
              }
            });
          
        }).catch(() => {
        
        });
    },
    

      
  },
};
</script>
  
  <style lang="scss" scoped>
.righttop {
  padding: 23px 26px 0 24px;
  display: flex;
  justify-content: space-between;
  .righttopright {
    span {
      width: 56px;

      margin-right: 15px;
    }
    span:nth-child(3) {
      margin: 0;
    }
  }
}
.rightmiddle {
  padding: 20px;
  display: flex;

  .xl {
    height: 30px;
    margin-right: 20px;
    border: 1px solid #999999;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-dropdown-link {
      cursor: pointer;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    p {
      font-size: 14px;
      color: #666666;
    }
  }
  .xl:nth-child(1) {
    width: 80px;
  }
  .xl:nth-child(2) {
    width: 130px;
  }
  .xl:nth-child(3) {
    width: 130px;
  }
  .xl:nth-child(4) {
    width: 72px;
  }
}
.rightbutton {
  margin-top: 20px;
  .rightbuttont {
    height: 40px;
    background: #e0af13;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666666;
    span {
      margin-right: 76px;
    }
  }
}
.tianjia {
  display: none;
}
.tianjia1 {
}
</style>